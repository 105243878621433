import { Block } from '@actovos-consulting-group/ui-core';
import firebase from 'firebase/app';
import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';
import Loader from './content-loaders/image';

const FirebaseImage = ({ src, width, useBackground, ...rest }) => {
  const [imgURL, setURL] = useState('/img/placeholder_square.jpg');
  const [loaded, setLoaded] = useState(false);

  useEffectOnce(() => {
    const ref = firebase.storage().ref(src);
    ref
      .getDownloadURL()
      .then(url => {
        setURL(url);
      })
      .catch(() => {
        //
      });
  });

  return (
    <Block width={width}>
      {!loaded && <Loader width={width} height={width} />}
      {imgURL && (
        <img
          onLoad={() => {
            setLoaded(true);
          }}
          alt="roast or toast"
          src={imgURL}
          style={{ width: '100%', display: useBackground ? 'none' : 'block' }}
          {...rest}
        />
      )}
      {imgURL && useBackground && loaded && (
        <Block
          background={`center / cover no-repeat url(${imgURL})`}
          width="100%"
          {...rest}
        />
      )}
    </Block>
  );
};

export default FirebaseImage;
