import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = props => (
  <ContentLoader viewBox="0 0 302.3 302.3" {...props}>
    <path d="M0,0v302.3h302.3V0H0z M285.7,285.7H16.6V16.6h269.1V285.7z" />
    <polygon points="16.6,285.7 16.6,229 101.4,114 191.1,219.3 238.4,174.1 286.2,229 286.2,285.7 	" />
    <circle cx="207.4" cy="103" r="25" />
  </ContentLoader>
);

export default Loader;
