import {
  Block,
  Card,
  Flex,
  Tag,
  Text,
} from '@actovos-consulting-group/ui-core';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { truncate } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import FirebaseImage from './firebase-image';

const StyledCard = styled(Card)`
  box-shadow: rgb(26 108 225 / 11%) 0px 0px 25px;
`;

const ProductCard = ({ product }) => {
  const cachedImage = getImage(product.remoteImage);

  return (
    <StyledCard mb={4}>
      <Flex position="relative">
        {product.score && (
          <Block
            p={2}
            bg="primary"
            borderRadius={5}
            position="absolute"
            top={-10}
            right={-10}
          >
            <Text fontWeight="bold" color="white" fontSize={{ _: 14, xs: 20 }}>
              {product.score}
            </Text>
          </Block>
        )}
        <Block width={{ _: 100, xs: 150 }}>
          {product.remoteImage && cachedImage ? (
            <GatsbyImage
              image={cachedImage}
              alt={product.name}
              style={{ borderRadius: 4, width: '100%' }}
              width="100%"
            />
          ) : (
            <FirebaseImage
              src={`products/${product.id}/product/${product.productPhoto}`}
              width="100%"
            />
          )}
        </Block>
        <Block px={{ _: 2, xs: 3 }} flex={1}>
          <Text
            as={Link}
            to={`/product/${product.slug}`}
            style={{ textDecoration: 'none' }}
            color="textColor"
            fontWeight="bold"
            mt={0}
            fontSize={{ _: 20, xs: 30 }}
          >
            {product.name}
          </Text>
          <Text mt={2}>{truncate(product.description, { length: 140 })}</Text>
        </Block>
      </Flex>
      {product.industries && (
        <Flex position="relative">
          <Block
            py={3}
            mt={3}
            style={{ width: '100%', borderTop: '1px solid #C4C9D4' }}
          >
            {product.industries.map(tag => (
              <Tag key={tag} size="lg" variant="light">
                {tag}
              </Tag>
            ))}
          </Block>
        </Flex>
      )}
    </StyledCard>
  );
};

export default ProductCard;
